import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  server: {
    CLERK_SECRET_KEY: z.string(),
  },
  client: {
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_LOB_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_GRAPHQL_URL: z.string().url(),
    NEXT_PUBLIC_GRAPHQL_PUBLISHABLE_KEY: z.string(),
    NEXT_PUBLIC_ALGOLIA_APPID: z.string(),
    NEXT_PUBLIC_ALGOLIA_KEY: z.string(),
    NEXT_PUBLIC_ALGOLIA_ALARM_COMPANY_INDEX_NAME: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: z.string(),
    NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: z.string(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_LOB_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_LOB_PUBLISHABLE_KEY,
    NEXT_PUBLIC_GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    NEXT_PUBLIC_GRAPHQL_PUBLISHABLE_KEY:
      process.env.NEXT_PUBLIC_GRAPHQL_PUBLISHABLE_KEY,
    NEXT_PUBLIC_ALGOLIA_APPID: process.env.NEXT_PUBLIC_ALGOLIA_APPID,
    NEXT_PUBLIC_ALGOLIA_KEY: process.env.NEXT_PUBLIC_ALGOLIA_KEY,
    NEXT_PUBLIC_ALGOLIA_ALARM_COMPANY_INDEX_NAME: process.env.NEXT_PUBLIC_ALGOLIA_ALARM_COMPANY_INDEX_NAME,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,
    NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL:
      process.env.NEXT_PUBLIC_CLERK_SIGN_IN_FORCE_REDIRECT_URL,
    NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL:
      process.env.NEXT_PUBLIC_CLERK_SIGN_UP_FORCE_REDIRECT_URL,
    NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL:
      process.env.NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL,
    NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL:
      process.env.NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
});
